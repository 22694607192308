// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import {getFirestore} from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAFEgV0bjekIcjrlhPi0tSui92d7Hz2NlM",
  authDomain: "chat-app-yt-a6301.firebaseapp.com",
  projectId: "chat-app-yt-a6301",
  storageBucket: "chat-app-yt-a6301.appspot.com",
  messagingSenderId: "1003074570652",
  appId: "1:1003074570652:web:202ca46dc84218879ce059"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)