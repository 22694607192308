import React from 'react';
import SignIn from './SignIn';
import LogOut from './LogOut';
import {auth} from '../firebase';
import {useAuthState} from 'react-firebase-hooks/auth';
import Profile from "./Profile";

const style = {
    nav: ` bg-grey-800 h-20 flex justify-between items-center p-4`,
    heading: ` text-black text-1xl`,
}

const Navbar = () => {
    const [user] = useAuthState(auth)
    console.log(user)
  return (
    <div className='nav-container'>
    <div className={style.nav}>
      <div className="name"><h1 className={style.heading}>Chrisyoungmusic</h1><div className='verify'></div></div>
      {user ? <LogOut /> : <SignIn />}

    </div>
    </div>
  );
};

export default Navbar;