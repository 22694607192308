import React from "react";






export default function Profile(){
         return(
            <div className="profile--container">
                <div className="pic"></div>
                <div className="name-profile-section"><div  className="name-profile">Chris Young</div><div className="profile-picture"></div></div>
                <div className="grey">chrisyoungmusic • Instagram</div>
                <div className="grey">1.5M followers • 2.7K posts</div>
                <div className="grey">You are following this Instagram account</div>
                <button className="profile-button" onClick={() => alert('Access Denied because this is a celebrity verified account')}>View profile</button>
                
            </div>
         )
}